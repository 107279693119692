<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    data(){
        return {

        }
    },
    mounted(e){
        var userAgent = navigator.userAgent
        if (userAgent.indexOf("Firefox") > -1) {
            $('#app').css( 'width','calc( 100vw - 15px)' )
        }
    }
}
</script>
