import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect:"/home",
  },{
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },{
    path: '/Trade',
    name: 'Trade',
    component: () => import(/* webpackChunkName: "about" */ '../views/Trade.vue')
  },{
    path: '/SBTPoints',
    name: 'SBTPoints',
    component: () => import(/* webpackChunkName: "about" */ '../views/SBTPoints.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
