<template>
	<div class="Footer">
        <div class="footer_box">
            <div class="footer_left">
                <div class="logo">
                    <router-link to="/home" style="display:block;width:100%;height:100%;"></router-link>
                </div>
                <p>Trading is Surfing. Enjoy the Crypto Waves.</p>
                <p>The first native DEX Aggregator on Linea, focusing on Layer2 ecosystem.</p>
            </div>
            <div class="footer_right">
                <ul>
                    <li>Products</li>
                    <li><a href="">Trade</a></li>
                </ul>
                <ul>
                    <li>Community</li>
                    <li><a href="https://twitter.com/surfiio">Twitter</a></li>
                    <li><a href="https://medium.com/@surfi.io">Medium</a></li>
                    <li><a href="https://discord.com/invite/jmuxH7mBHP">Discord</a></li>
                </ul>
                <ul>
                    <li>Support</li>
                    <li><a href="https://drive.google.com/drive/folders/1T3XPznjkZwpKyjcCQN_r1Skglbnod0ga?usp=sharing">Brand Kit</a></li>
                </ul>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			
		}
	},
	methods: {
		
	}
}
</script>
