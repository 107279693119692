<template>
	<div class="Header">
		<div class="header_box_pc">
			<div class="header_box">
				<div class="logo">
					<router-link to="/home" style="display:block;width:100%;height:100%;"></router-link>
				</div>
				<div class="nav">
					<div>
						<a href="https://swap-linea.surfi.io/swap" rel="noopener noreferrer">Trade</a>
						<!-- <router-link class="link" to="/home">Trade</router-link> -->
						<div :class=" routeText == 'Trade' ? 'border active' : 'border'"></div>
						<!-- <div class="comingsoon">Coming Soon</div> -->
					</div>
					<div>
						<router-link class="link" to="/SBTPoints">SBT Points</router-link>
						<!-- <router-link class="link" to="/home">SBT Points</router-link> -->
						<div :class=" routeText == 'SBTPoints' ? 'border active' : 'border'"></div>
						<!-- <div class="comingsoon">Coming Soon</div> -->
					</div>
				</div>
				<div class="header_right">
					<div class="tw"><a style="width:100%;height:100%;display:block;" href="https://twitter.com/surfiio" target="_black"></a></div>
					<div class="dc"><a style="width:100%;height:100%;display:block;" href="https://discord.com/invite/jmuxH7mBHP" target="_black"></a></div>
					<!-- <div class="theme" @click="toggoleTheme"></div> -->
					<div class="network">
						<div @click="changeNetworkBtn">
							<div></div> 
							<p>Linea</p>
						</div>
						<div class="networkList" v-if="networkListShow">
							<p class="title"><span>Select a Chain</span><span @click="networkListShow = false"></span></p>
							<ul>
								<li>
									<p @click="changenetworkThis('zksync1')"><span class="changeLoco"><img src="../assets/linea-black.png" alt="">Linea</span><img class="changeRight" :src="changeNetworkType == 'zksync1' ? changeRightok : changeRightno" alt=""></p>
								</li>
								<!-- <li>
									<p @click="changenetworkThis('zksync2')"><img class="changeLoco" src="../assets/changeLogo-zk.png" alt=""><img class="changeRight" :src="changeNetworkType == 'zksync2' ? changeRightok : changeRightno" alt=""></p>
								</li> -->
							</ul>
						</div>
						<div class="closeAll"  v-if="networkListShow" @click="networkListShow = false"></div>
						<!-- <div class="globalimg"></div> -->
					</div>
					<div class="connectWallect" @click="showBox">
						<span class="useraddress"  v-if="userAddress">{{ userAddress | filtersPcUsrAddr }} | {{ERC20Balance == 0 ? 0 : ERC20Balance.toFixed(3)}}</span>
						<span v-else >Connect</span>
						<div :class="userAddress ? 'ok' : 'no'"></div>
					</div>
				</div>
			</div>
			
		</div>

		<div class="header_box_iphone">
			<div class="header_box">
				<div class="iphone_top">
					<div class="logo">
						<div class="logo_img"><router-link to="/home" style="display:block;width:100%;height:100%;"></router-link></div>
						<div class="menu" @click="navShowBtn"></div>
					</div>
				</div>
				<div :class='navShow ? "nav nav_show" : "nav"' @click="navShowBtn">
					<div class="box">
						<div>
							<a class="link link1" href="https://swap-linea.surfi.io/swap" rel="noopener noreferrer"><span></span> Trade</a>
							<!-- <router-link class="link link1" to="/Trade"><span></span> Trade</router-link> -->
						</div>
						<div>
							<router-link class="link link2" to="/SBTPoints"><span></span> SBT Points</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="header_bottom">
				<div class="bottom_left">
					<div class="connectWallet">
						<span class="useraddress" @click="showBox" v-if="userAddress">{{ userAddress | filtersPcUsrAddr }} | {{ERC20Balance == 0 ? 0 : ERC20Balance.toFixed(3)}} ETH</span>
						<span @click="showBox" v-else >Connect</span>
						<div :class="userAddress ? 'ok' : 'no'"></div>
					</div>
				</div>
				<div class="bottom_right">
					<div class="tw"><a style="width:100%;height:100%;display:block;" href="https://twitter.com/surfiio" target="_black"></a></div>
					<div class="dc"><a style="width:100%;height:100%;display:block;" href="https://discord.com/invite/jmuxH7mBHP" target="_black"></a></div>
					<!-- <div class="theme" @click="toggoleTheme"></div> -->
					<!-- <div class="network">
						<div></div>
						<div></div>
					</div> -->
					<div class="network">
						<div @click="changeNetworkBtn">
							<div></div> 
							<p>Linea</p>
						</div>
						<div class="networkList" v-if="networkListShow">
							<p class="title"><span>Select a Chain</span><span @click="networkListShow = false"></span></p>
							<ul>
								<li>
									<p @click="changenetworkThis('zksync1')"><span class="changeLoco"><img src="../assets/linea-black.png" alt="">Linea</span><img class="changeRight" :src="changeNetworkType == 'zksync1' ? changeRightok : changeRightno" alt=""></p>
								</li>
								<!-- <li>
									<p @click="changenetworkThis('zksync2')"><img class="changeLoco" src="../assets/changeLogo-zk.png" alt=""><img class="changeRight" :src="changeNetworkType == 'zksync2' ? changeRightok : changeRightno" alt=""></p>
								</li> -->
							</ul>
						</div>
						<div class="closeAll"  v-if="networkListShow" @click="networkListShow = false"></div>
						<!-- <div class="globalimg"></div> -->
					</div>
				</div>
				
				
			</div>
		</div>


		<div class="connect_box" v-if="coonectBoxShow" @click="coonectBoxShow = false">
			<div class="warp" v-on:click.stop>
				<p class="title"><span>Your Wallet</span><span @click="coonectBoxShow = false"></span></p>
				<div class="info">
					<div class="left">
						<div class="metamaskIcon" id="metamaskIcon"></div>
						<div class="metamaskInfo">
							<p class="fz14">Account</p>
							<p class="fz12">{{ userAddress | filtersPc8UsrAddr }} <img @click="copy" src="../assets/copy.png" alt=""></p>
						</div>
					</div>
					<div class="right">
						<p class="fz14">{{ ERC20Balance.toFixed(3) }}</p>
						<p class="fz12">≈{{ Number(ERC20Balance*ETHPrice).toFixed(3) }} USD</p>
					</div>
				</div>
				<div class="btns">
					<div :class="connectClickIndex == 1 ? 'active' : ''" @click="logout">Log out</div>
					<!-- <div :class="connectClickIndex == 2 ? 'active' : ''">Order History</div> -->
					<div :class="connectClickIndex == 3 ? 'active' : ''" @click="goView">View on Linea <img src="../assets/link.png" alt=""></div>
				</div>
			</div>
		</div>

		<div class="wallet_box" v-if="walletBoxShow" @click="walletBoxShow = false">
			<div class="warp" v-on:click.stop>
				<p class="title"><span>Connect</span><span @click="walletBoxShow = false"></span></p>
				<p class="test">Connect wallet in one click to start using SURFI</p>
				<div class="info">
					<div @click="connect('METAMASK')">
						<div>
							<img src="../assets/metamask.png" alt="" srcset="">
							<p>METAMASK</p>
						</div>
					</div>
					<div @click="connect('WALLETCONNECT')">
						<div>
							<img src="../assets/walletconnect.svg" alt="" srcset="">
							<p>Wallet Connect</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import Web3 from 'web3'
import { metamaskIcon } from '../utils/wallet.js'
export default {
	filters: {
		filtersPc8UsrAddr(str){
			var firstStr =   str.substr(0,8);
			var lastStr = str.substr(38);
			var middleStr = '…';
			var tempStr = firstStr + middleStr + lastStr;
			return tempStr;
		},
		filtersPcUsrAddr(str){
			var firstStr =   str.substr(0,4);
			var lastStr = str.substr(38);
			var middleStr = '…';
			var tempStr = firstStr + middleStr + lastStr;
			return tempStr;
		},
		filtersIphoneUsrAddr(str){
			var firstStr =   str.substr(0,4);
			var lastStr = str.substr(38);
			var middleStr = '…';
			var tempStr = firstStr + middleStr + lastStr;
			return tempStr;
		}
	},
	watch: {
		coonectBoxShow: function (newValue, oldValue) {
			if (newValue) {
				document.body.style.position = 'fixed'
			} else {
				document.body.style.position = 'unset'
			}
		},
		walletBoxShow: function (newValue, oldValue) {
			if (newValue) {
				document.body.style.position = 'fixed'
			} else {
				document.body.style.position = 'unset'
			}
		}
	},
	data() {
		return {
			theme: 'black',
			routeText:'Home',
			coonectBoxShow:false, walletBoxShow:false,
			userAddress:'',//'0x7bFeBDFBff6ad1ecc4a740AB8d76f37c5000B8dB'
			ERC20Balance:0,ETHPrice:0,metamaskIconText:'',
			walletId:0,connectClickIndex:0,
			navShow:false,
			networkListShow: false,changeNetworkType: 'zksync1',changeRightok: require('../assets/changeRight-ok.png'),changeRightno: require('../assets/changeRight-no.png'),

		}
	},
	created(){
		this.routeText = this.$route.name
		this.userAddress = localStorage.getItem('userAddress') || ''
		if (this.userAddress != '') {
			this.walletId = 1
			this.getERC20Balance()
			this.$axios.get('https://api.dexscreener.com/latest/dex/pairs/zksync/0x80115c708e12edd42e504c1cd52aea96c547c05c').then(res => {
				this.ETHPrice = res.pair.priceUsd
            })
		}
	},
	methods: {
		changeNetworkBtn(){
			this.networkListShow = !this.networkListShow
		},
		changenetworkThis(type){
			this.changeNetworkType = type
			this.networkListShow = false
		},
		toggoleTheme() {
			this.theme = this.theme === 'white' ? 'black' : 'white'
			document.documentElement.setAttribute('data-theme', this.theme );
		},
		getERC20Balance(){
			ethereum.request({
				method: 'eth_getBalance',
				params: [this.userAddress],
			}).then((success) => {
				this.ERC20Balance = Number(success/1e18)
			})
		},
		showBox(){
			if (this.userAddress == '') {
				this.walletBoxShow = true
			} else {
				this.coonectBoxShow = true
				metamaskIcon(this.userAddress,40).then(metamaskIconRes => {
					document.getElementById('metamaskIcon').appendChild(metamaskIconRes);
				})
			}
		},
		clearUseraddress(){
			if (this.userAddress != '') {
				this.userAddress = ''
				localStorage.removeItem('userAddress');
				this.walletId = 0
			} else {
				this.coonectBoxShow = true
			}
		},
		changeRe(){
			// console.log(1)
		},
		connect(type){
			if (type == 'METAMASK') { this.walletId = 1; } 
			if (type == 'WALLETCONNECT') { this.walletId = 2; } 
			window.ethereum.request({method:'net_version'}).then(res => {
				if (res != 59144) {
					window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{
                            'chainId': Web3.utils.toHex(59144),
                        }],
                    }).then((success) => {

                    })
					// window.ethereum.request({
                    //     method: 'wallet_addEthereumChain',
                    //     params: [{
                    //         'chainId': Web3.utils.toHex(59144),
                    //         'chainName': 'Linea Goerli test network',
                    //         'nativeCurrency': {
                    //             'name': 'LineaETH',
                    //             'symbol': 'LineaETH',
                    //             'decimals': 18,
                    //         },
                    //         'rpcUrls': ['https://linea-goerli.infura.io'],
                    //         'blockExplorerUrls': ['https://explorer.goerli.linea.build'],
                    //     }],
                    // }).then((success) => {

                    // })
				} else {
					window.ethereum.request({
						method:'eth_requestAccounts'
					}).then(res => {
						console.log(res)
						this.connectClickIndex = 0
						this.userAddress = res[0];
						localStorage.setItem('userAddress',res[0]);
						this.getERC20Balance()
						this.walletBoxShow = false;
					}).catch(err => {
						this.walletId = 0
					})
				}
			})
			return
			
		},
		logout(){
			this.connectClickIndex = 1
			this.userAddress = ''
			localStorage.removeItem('userAddress');
			this.walletId = 0
			this.coonectBoxShow = false
		},
		goView(){
			this.connectClickIndex = 3
			window.open(`https://explorer.goerli.linea.build/address/${this.userAddress}`,'_block')
		},
		changeNetwork(){
			// console.log(1)
		},
		noMoreTips(){
			// console.log(1)
		},
		navShowBtn(){
			// return
			this.navShow = !this.navShow
		},
		copy(){
			var oInput = document.createElement('input');
			oInput.value = this.userAddress;
			document.body.appendChild(oInput);
			oInput.select();
			document.execCommand("Copy");
			oInput.className = 'oInput';
			oInput.style.display='none';
		}
		
	}
}
</script>
