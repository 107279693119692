import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ajax from 'jquery'
import axios from './utils/axios.js'
import './utils/wallet.js'
import './css/font.css';
import './css/reset.css';
import './css/css.scss';
import './css/box.scss';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
// Vue.use(ElementUI)
Vue.use(ElementUI, { locale })

Vue.component('v-header', Header)
Vue.component('v-footer', Footer)
Vue.config.productionTip = false

Vue.prototype.$ajax = $ajax;
Vue.prototype.$axios = axios;
Vue.prototype.$isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
